import React from 'react';
import {
  Button,
  Select,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Divider,
  message,
} from 'antd';
import type {TableColumnsType, FormInstance} from 'antd';
import {
  useUpdateSystemConfig,
  useCreateSystemConfig,
} from 'hooks/useSystemConfig/useUpdateSystemConfig';
import {formatStringToNumber,formatNumberToString} from 'utils/index';
const {Option} = Select;

interface Props {
  isId: string;
  setIsId: (value: string) => void;
  setIsShowPopup: (value: boolean) => void;
  isShowPopup: boolean;
  form: FormInstance<any>;
  refetch: () => void;
}
const valueModal = [
  'costPerKm',
  'shopFeePerOrder',
  'shipFeePerOrder',
  'costPerKmAfterSeed',
  'shipFeePerOrderAfter',
  'distanceWithCostSimply',
];

export const FormSystem: React.FC<Props> = ({
  isId,
  setIsId,
  isShowPopup,
  setIsShowPopup,
  form,
  refetch,
}) => {
  const {mutateAsync, isLoading: isCreate} = useCreateSystemConfig();
  const {mutateAsync: onUpdateUser, isLoading: isEdit} =
    useUpdateSystemConfig();
  const onSubmit = async () => {
    try {
      const value = await form.validateFields();
      const isPercentShopFee = form.getFieldValue('isPercentShopFee');
      const data = {
        ...value,
        shopFeePerOrder: formatStringToNumber(value.shopFeePerOrder),
        costPerKm: formatStringToNumber(value.costPerKm),
        costPerKmAfterSeed: formatStringToNumber(value.costPerKmAfterSeed),
        distanceWithCostSimply: formatStringToNumber(
          value.distanceWithCostSimply,
        ),
        isPercentShopFee,
      };
      if (isId) {
        await onUpdateUser({
          id: isId,
          body: data,
        });
      } else {
        await mutateAsync(data);
      }
      await refetch();
      setIsShowPopup(false);
      setIsId('');
      form.resetFields();
    } catch (error) {
      console.log(error)
      if(error?.data?.message){
        message.error(error?.data?.message);
      }
    }
  };

  const selectAfter = (filed: string,filedValue: string) => {
    const value = form.getFieldValue(filed);
    return (
      <Select
        style={{width: '80px'}}
        defaultValue={value ? 'percent' : 'vnd'}
        onChange={e => {
            form.setFieldsValue({[filed]: e === 'percent' ? true : false});
            form.setFieldsValue({[filedValue]: ''});
        }}>
        <Option value="percent">%</Option>
        <Option value="vnd">VNĐ</Option>
      </Select>
    );
  };
  const normalizePrice = (value: any) => {
    return formatNumberToString(value);
  };

  return (
    <Modal
      title={!isId ? 'Thêm Config Shop' : 'Chỉnh sửa Config Shop'}
      open={isShowPopup}
      width={800}
      okText="Lưu"
      cancelText="Huỷ"
      onOk={onSubmit}
      confirmLoading={isCreate || isEdit}
      onCancel={() => {
        form.resetFields();
        setIsShowPopup(false);
      }}>
      <div className="container mx-auto ">
        <Divider className="my-2" />
        <Form form={form} className="py-1" >
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Tên System Config</label>
                <Form.Item
                  name="name"
                  className="mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Loại System không được bỏ trống !',
                    },
                  ]}>
                  <Input placeholder="Tên System Config" />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Km tối thiểu</label>
                <Form.Item
                  name="distanceWithCostSimply"
                  className="mb-0"
                  normalize={normalizePrice}
                  rules={[
                    {
                      required: true,
                      message: 'Không được bỏ trống!',
                    },
                  ]}>
                  <Input placeholder="Km tối thiểu" addonAfter="KM" />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Chi phí mỗi KM đầu tiên</label>
                <Form.Item
                  name="costPerKm"
                  className="mb-0"
                  normalize={normalizePrice}
                  rules={[
                    {
                      required: true,
                      message: 'Không được bỏ trống!',
                    },
                  ]}>
                  <Input placeholder="Chi phí mỗi KM" addonAfter="VNĐ" />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">
                  Chi phí sau Km tối thiểu
                </label>
                <Form.Item
                  name="costPerKmAfterSeed"
                  className="mb-0"
                  normalize={normalizePrice}
                  rules={[
                    {
                      required: true,
                      message: 'Không được bỏ trống!',
                    },
                  ]}>
                  <Input
                    placeholder="Chi phí sao Km tối thiểu"
                    addonAfter="VNĐ"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Phí cửa hàng</label>
                <Form.Item
                  name="shopFeePerOrder"
                  className="mb-0"
                  normalize={normalizePrice}
                  rules={[
                    {
                      required: true,
                      message: 'Không được bỏ trống!',
                    },
                    {
                        validator: async (rule, value) => {
                          const isCheck = form.getFieldValue('isPercentShopFee');
                          if(isCheck){
                             const numberPercent = formatStringToNumber(value);
                             if(numberPercent > 100){
                              throw new Error('Phí giao hàng hàng không thể lớn hơn 100%');
                             }
                          }
                        }
                    }
                  ]}>
                  <Input placeholder="Phí của hàng" addonAfter={selectAfter('isPercentShopFee','shopFeePerOrder')} />
                </Form.Item>
              </div>
            </Col>
            {/* <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Phí shiper</label>
                <Form.Item
                  name="shipFeePerOrder"
                  className="mb-0"
                  normalize={normalizePrice}
                  rules={[
                    {
                      required: true,
                      message: 'Không được bỏ trống!',
                    },
                    {
                        validator: (rule, value, callback) => {
                          const isCheck = form.getFieldValue('isPercentShipFee');
                          if(isCheck){
                             const numberPercent = formatStringToNumber(value);
                             if(numberPercent > 100){
                                 callback('Phí giao hàng hàng không thể lớn hơn 100%');
                             }
                             else{
                                callback()
                             }
                          }
                        }
                    }
                  ]}>
                  <Input placeholder="Phí shiper" addonAfter={selectAfter('isPercentShipFee','shipFeePerOrder')} />
                </Form.Item>
              </div>
            </Col> */}
          </Row>
        </Form>
        <Divider className="my-2" />
      </div>
    </Modal>
  );
};
