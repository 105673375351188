import { AxiosError } from 'axios';
import Api from "configs";
import { useQuery } from "@tanstack/react-query";
import GetFilters from 'types/GetFilter';
import GetResponse from 'types/GetResponse';

export const useGetVouchers = (filters: GetFilters) => {
    const {
        pageSize = 10,
        page = 1
    } = filters;
    const options = {
        query: {},
        pageSize: pageSize,
        page: page
    }
    return useQuery<GetResponse, AxiosError>({
        queryKey: ['useGetVouchers', options], 
        queryFn: async () => {
            const { data } = await Api.get('vouchers/admin', { params: options });
            return data;
        },
        
    });
}