import { AxiosError } from 'axios';
import Api from "configs";
import { useQuery, useMutation, useQueryClient} from "@tanstack/react-query";
import GetResponse from 'types/GetResponse';
import GetFilters from 'types/GetFilter';

export const useGetShops = (filters: GetFilters) => {
    const { page = 1, pageSize = 10, search = '', sorter = null, } = filters;
    const options = {
        query: {},
        pageSize: pageSize,
        page: page,
        sort: sorter
    }
    if(search){
        options.query = {
         ...options.query,
            search: search
        }
    }
    return useQuery<GetResponse, AxiosError>({
        queryKey: ['useGetShops', options],
        queryFn: async () => {
            const { data } = await Api.get('/admin/shops', { params: options });
            return data;
        },
    });
}

export const useGetShopById = (id: string) => {
    return useQuery({
        queryKey: ['useGetShopById', id],
        queryFn: async () => {
            const { data } = await Api.get(`/admin/shops/${id}`);
            return data;
        },
    });
}

export const useUnblockShip = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        {
            mutationKey: ['useUnblockShip'],
            mutationFn: async (varblie: {shopId: string, shipId: string}) => {
                const  {shipId, shopId} = varblie;
                const { data } = await Api.put(`/admin/shops/unblock/${shopId}/${shipId}`);
                queryClient.invalidateQueries(['useGetShopById', shopId]);
                return data;
            },
        }
    )
}
