import React from 'react';
import {Pagination} from 'antd';

type propPagination = {
  totalPage: number;
  current: number;
  pageSize: number;
  onChange: (page: number, pageSize: number) => void;
  onShowSizeChange: (current: number, pageSize: number) => void;
};

export const PaginationCustom: React.FC<propPagination> = props => {
  const {current, pageSize, totalPage, onChange, onShowSizeChange} = props;
  const onChangeValue = (page: number, limit: number)=>{
    if(limit !== pageSize){
        onChange(1,limit);
    }
    else {
       onChange(page,pageSize);
    }
  }
  return (
    <Pagination
      current={current}
      pageSize={pageSize}
      total={totalPage}
      showSizeChanger
      onChange={onChangeValue}
      onShowSizeChange={onShowSizeChange}
    />
  );
};
