import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import {publicRouter} from 'routers/publicRouters';
import {privateRouters} from 'routers/privateRouters';
import {DashboardPage} from 'layouts/dashboard';
import {useAuthStore} from 'stores/auth';
import './App.css';

function App() {
  const isAuthenticated = useAuthStore(state => state.isAuthenticated);
  
  // Config providers zustand store, antd, routers providers
  return (
    <Routes>
      <Route
        path="/"
        element={<DashboardPage isAuthenticated={isAuthenticated} />}>
        {privateRouters.map(item => {
          return (
            <Route key={item.path} path={item.path} element={item.component} />
          );
        })}
      </Route>
      {publicRouter.map(item => {
        return (
          <Route path={item.path} element={item.component} key={item.path} />
        );
      })}
      <Route path="*" element={<Navigate to={'login'} />} />
    </Routes>
  );
}

export default App;
