import { AxiosError } from 'axios';
import Api from "configs";
import { useQuery, useMutation } from "@tanstack/react-query";
import GetResponse from 'types/GetResponse';
import GetFilters from 'types/GetFilter';

export const useGetShipers = (filters: GetFilters) => {
    const {
        pageSize = 10,
        page = 1,
        search = '',
        sorter = null,
    } = filters;
    const options = {
        query: {},
        pageSize: pageSize,
        page: page,
        sort: sorter
    }
    if (search) {
        options.query = {
            ...options.query,
            search: search
        }
    }
    return useQuery<GetResponse, AxiosError>({
        queryKey: ['useGetShipers', options],
        queryFn: async () => {
            const { data } = await Api.get('/admin/shipers', { params: options });
            return data;
        },

    });
}

