import { AxiosError } from 'axios';
import Api from "configs";
import { useQuery } from "@tanstack/react-query";
import GetResponse from 'types/GetResponse';
import GetFilters from 'types/GetFilter';

export const useGetSystemConfig = (filters: GetFilters) => {
    const {
        pageSize = 10,
        page = 1,
        name,
        typeSystem
    } = filters;
    const options = {
        query: {},
        pageSize: pageSize,
        page: page
    }
    if (name) {
        options.query = {
            ...options.query,
            name: name
        }
    }
    if(typeSystem){
        options.query = {
           ...options.query,
            type: typeSystem
        }
    }
    return useQuery<GetResponse, AxiosError>({
        queryKey: ['useGetSystemConfig', options],
        queryFn: async () => {
            const { data } = await Api.get('/system-config', { params: options });
            return data;
        },

    });
}


export const useGetSystemConfigById = (id: string) => {
    return useQuery({
        queryKey: ['useGetSystemConfigById', id],
        queryFn: async () => {
            const { data } = await Api.get(`/system-config/${id}`);
            return data;
        },

    });
}



export const useGetShopToSystem = (filters: GetFilters) => {
    const {
        pageSize = 10,
        page = 1,
        systemConfigId
    } = filters;
    const options = {
        query: {},
        pageSize: pageSize,
        page: page
    }
    if (systemConfigId) {
        options.query = {
            ...options.query,
            systemConfigId: systemConfigId
        }
    }
    return useQuery<GetResponse, AxiosError>({
        queryKey: ['useGetShopToSystem', options],
        queryFn: async () => {
            const { data } = await Api.get('/admin/shops/systemConfig/list', { params: options });
            return data;
        },
    })
}

export const useGetShipToSystem = (filters: GetFilters) => {
    const {
        pageSize = 10,
        page = 1,
        systemConfigId
    } = filters;
    const options = {
        query: {},
        pageSize: pageSize,
        page: page
    }
    if (systemConfigId) {
        options.query = {
            ...options.query,
            systemConfigId: systemConfigId
        }
    }
    return useQuery<GetResponse, AxiosError>({
        queryKey: ['useGetShopToSystem', options],
        queryFn: async () => {
            const { data } = await Api.get('/admin/shipers/systemConfig/list', { params: options });
            return data;
        },
    })
}