import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Tag, Input} from 'antd';
import type {TableColumnsType} from 'antd';
import {ReadOutlined, RollbackOutlined} from '@ant-design/icons';
import {useGetUserDetails} from 'hooks/users/useGetUsers';
import {useGetOrdersByUser} from 'hooks/order/useGetOrder';
import type {SearchProps} from 'antd/es/input/Search';

import {TableCustom} from 'components/element/TableCustom';
import {PaginationCustom} from 'components/element/PaginationCustom';
import {addKeyData, formatNumberValue, formatDate} from 'utils';
export const OrderUser: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [filters, setFilters] = useState({});
  const {data: getData, isLoading} = useGetOrdersByUser({
    ...filters,
    userID: params?.id,
  });
  const {data: client, isLoading: isLoadingClient} = useGetUserDetails(
    params?.id || '',
  );
  const statusOrder: Record<string, string> = {
    canceled: 'red',
    initialize: 'blue',
    'ship-confirmed': 'orange',
    'ship-delivery': 'lime',
    completed: 'green',
  };
  const columns: TableColumnsType = [
    {title: 'Mã Order', dataIndex: 'code', key: 'code', align: 'center'},
    {
      title: 'Địa chỉ người nhận',
      dataIndex: 'addressReceiver',
      key: 'addressReceiver',
      align: 'center',
    },
    {
      title: 'Tên Shop',
      dataIndex: 'shop',
      key: 'shop',
      align: 'center',
      render: (data: any) => {
        return <div>{data?.name}</div>;
      },
    },
    {
      title: 'Tên người nhận',
      dataIndex: 'client',
      key: 'client',
      align: 'center',
      render: (data: any) => {
        return <div>{data?.name}</div>;
      },
    },
    {
      title: 'Phụ phí',
      dataIndex: 'subFee',
      key: 'subFee',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatNumberValue(data)}</p>
          </div>
        );
      },
    },
    {
      title: 'Phí Ship',
      dataIndex: 'shipFee',
      key: 'shipFee',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatNumberValue(data)}</p>
          </div>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (data: string) => {
        return (
          <Tag color={statusOrder[`${data}`]}>{data.toLocaleUpperCase()}</Tag>
        );
      },
      filters: [
        {value: 'initialize', text: 'Initialize'},
        {value: 'ship-confirmed', text: 'Ship Confirmed'},
        {value: 'ship-delivery', text: 'Ship Delivery'},
        {value: 'completed', text: 'Completed'},
        {value: 'canceled', text: 'Canceled'},
      ],
    },
    {
      title: 'Ngày đặt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatDate(data)}</p>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      align: 'center',
      width: 200,
      render: (record: any) => {
        return (
          <div className="flex justify-center gap-4 px-0">
            <Button
              onClick={() => {
                navigate(`/orders/${record?._id}`, {
                  state: {from: `/orders`},
                });
              }}
              icon={<ReadOutlined />}
              className="!border-white	 bg-[#eca52b] hover:!bg-[#edb555] !text-white !font-medium">
              Chi tiết đơn hàng
            </Button>
          </div>
        );
      },
    },
  ];
  const handleTableChange = (filter: any, sorter: any) => {
    const {status} = filter;
    if (status) {
      setFilters({...filters, status: status});
    } else {
      setFilters({...filters, status: []});
    }
  };
  const onSeachValue: SearchProps['onSearch'] = (value, _e, info) => {
    setFilters({...filters, code: value});
  };
  // useEffect(() => {
  //   setFilters();
  // }, [params?.id]);
  return (
    <div className="container mx-auto">
      <div className="flex-col gap-4">
        <div className="flex justify-between items-center">
          <div style={{padding: '20px 0px'}}>
            <h2 className="text-2xl font-bold" style={{margin: 0}}>
              Lịch sử mua hàng của: {client?.name}
            </h2>
            <p style={{padding: '6px 0'}}>
              {' '}
              Tống số lượng đơn hoàn thành: {getData?.countCompleted ??
                0} / {getData?.totalCount ?? 0}{' '}
            </p>
          </div>
          <div className="flex gap-2">
            <Button
              icon={<RollbackOutlined />}
              onClick={() => {
                navigate(-1);
              }}>
              Trở về
            </Button>
            <Input.Search
              className="w-96"
              placeholder="Tìm kiếm mã đơn hàng"
              onSearch={onSeachValue}
              enterButton
            />
          </div>
        </div>
        <div className=" flex flex-col items-end gap-3">
          <TableCustom
            isLoading={isLoading}
            columns={columns}
            data={addKeyData(getData?.result)}
            onChange={handleTableChange}
          />
          <PaginationCustom
            totalPage={getData?.totalPage ?? 1}
            current={getData?.page ?? 1}
            pageSize={getData?.pageSize ?? 10}
            onChange={(page, pageSize) => {
              console.log(page, pageSize);
              setFilters({...filters, page, pageSize});
            }}
            onShowSizeChange={(current, size) => {
              setFilters({...filters, page: current, pageSize: size});
            }}
          />
        </div>
      </div>
    </div>
  );
};
