import { AxiosError } from 'axios';
import Api from "configs";
import { useQuery, useMutation } from "@tanstack/react-query";
import GetResponse from 'types/GetResponse';
import GetFilters from 'types/GetFilter';


export const useGetListCategory = () => {
    return useQuery({
        queryKey: ['useGetListCategory'],
        queryFn: async () => {
            const { data } = await Api.get('/category');
            return data;
        },
    });
}

export const useGetCategory = (filters: GetFilters) => {
    const {
        pageSize = 10,
        page = 1,
        search = ''
    } = filters;
    const options = {
        query: {},
        pageSize: pageSize,
        page: page
    }
    if (search) {
        options.query = {
            ...options.query,
            search: search
        }
    }
    return useQuery<GetResponse, AxiosError>({
        queryKey: ['useGetCategory', options],
        queryFn: async () => {
            const { data } = await Api.get('/admin/category', { params: options });
            return data;
        },

    });
}



export const useCreateCategory = () => {
    return useMutation({
        mutationKey: ['useCreateCategory'],
        mutationFn: async (body: any) => {
            const { data } = await Api.post(`category`, body);
            return data;
        }
    })
}


export const useUpdateCategory = () => {
    return useMutation({
        mutationKey: ['useUpdateCategory'],
        mutationFn: async (variables: { body: any, id: string }) => {
            const { id, body } = variables;
            const { data } = await Api.put(`/category/${id}`, body);
            return data;
        }
    })
}
