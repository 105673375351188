import React, {useState} from 'react';
import { Rate,Input } from 'antd';
import type {TableColumnsType,} from 'antd';
import {addKeyData,formatDate} from 'utils';
import type {SearchProps} from 'antd/es/input/Search';

import {TableCustom} from 'components/element/TableCustom';
import {PaginationCustom} from 'components/element/PaginationCustom';
import {useGetReport} from 'hooks/report';
export const Reports: React.FC = () => {
  const [filters, setFilters] = useState({});
  const {data: getData, isLoading} = useGetReport(filters);
  const columns: TableColumnsType = [
    {
      title: 'Mã đơn hàng',
      dataIndex: 'order',
      key: 'code',
      render: (data: any) => {
        return <div>{data?.code}</div>;
      },
      align: 'center',
    },
    {
      title: 'Tên Người đánh giá',
      dataIndex: 'client',
      key: 'client',
      align: 'center',
      render: (data: any) => {
        return <div>{data?.name}</div>;
      },
    },
    {
      title: 'Tài xế',
      dataIndex: 'ship',
      key: 'ship',
      align: 'center',
      render: (data: any) => {
        return <div>{data?.name}</div>;
      },
    },
    {
        title: 'Nội dung',
        dataIndex: 'reason',
        key: 'reason',
        align: 'center',
    },
    {
        title: 'Đánh giá',
        dataIndex: 'rating',
        key: 'rating',
        align: 'center',
        render: (data: number) => {
            return <Rate defaultValue={data} disabled />;
          },
    },
    {
        title: 'Ngày báo cáo',
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'center',
        render: data => {
          return (
            <div>
              <p>{formatDate(data)}</p>
            </div>
          );
        },
      },
  ];
  const onSeachValue: SearchProps['onSearch'] = (value, _e, info) => {
    setFilters({...filters, search: value});
  };
  return (
    <div className="container mx-auto">
      <div className="flex-col gap-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Danh sách đánh giá tài xế</h2>
          <div>
              <Input.Search
                className="w-96"
                placeholder="Tìm kiếm theo tên tài xế hoặc mã đơn hàng"
                onSearch={onSeachValue}
                enterButton
              />
            </div>
        </div>
        <div className=" flex flex-col items-end gap-3">
          <TableCustom
            isLoading={isLoading}
            columns={columns}
            data={addKeyData(getData?.result)}
          />
          <PaginationCustom
            totalPage={getData?.totalPage ?? 1}
            current={getData?.page ?? 1}
            pageSize={getData?.pageSize ?? 10}
            onChange={(page, pageSize) => {
              console.log(page, pageSize);
              setFilters({...filters, page, pageSize});
            }}
            onShowSizeChange={(current, size) => {
              setFilters({...filters, page: current, pageSize: size});
            }}
          />
        </div>
      </div>
    </div>
  );
};
