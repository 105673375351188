import { AxiosError } from 'axios';
import Api from "configs";
import { useQuery } from "@tanstack/react-query";
import GetResponse from 'types/GetResponse';
import GetFilters from 'types/GetFilter';


export const useGetBanlance = (filters: GetFilters) => {
    const {
        pageSize = 10,
        page = 1,
        userID = ''
    } = filters;
    const options = {
        query: {
           
        },
        pageSize: pageSize,
        page: page
    }
    return useQuery<GetResponse, AxiosError>({
        queryKey: ['useGetBalance', options, userID], 
        queryFn: async () => {
            const { data } = await Api.get(`/admin/balance/${userID}`, { params: options });
            return data;
        },
        staleTime: 1000 * 60 * 5
    });
}