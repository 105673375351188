import { AxiosError } from 'axios';
import Api from "configs";
import { useMutation } from "@tanstack/react-query";
import UpdateUser from "types/user/index";

export const useUpdateUser = () => {
    return useMutation({
        mutationKey: ['UpdateUser'],
        mutationFn: async (variables: { body: UpdateUser, idUser: string }) => {
            const { idUser, body } = variables;
            const { data } = await Api.put(`/admin/client/${idUser}`, body);
            return data;
        }
    })
}

export const useToggleUser = () => {
    return useMutation({
        mutationKey: ['useToggleUser'],
        mutationFn: async (idUser: string) => {
            const { data } = await Api.put(`/admin/toggleBlock/${idUser}`);
            return data;
        }
    })
}

export const useVerifyUser = () => {
    return useMutation({
        mutationKey: ['useVerifyUser'],
        mutationFn: async (idUser: string) => {
            const { data } = await Api.put(`/admin/verify/${idUser}`);
            return data;
        }
    })
}



export const useCreateUser = () => {
    return useMutation({
        mutationKey: ['createUser'],
        mutationFn: async (body: UpdateUser) => {
            const { data } = await Api.post(`/admin/client`, body);
            return data;
        }
    })
}

export const useLoadedMoney = () => {
    return useMutation({
        mutationKey: ['useLoadedMoney'],
        mutationFn: async (body: any) => {
            const { data } = await Api.post(`/admin/balance`,body);
            return data;
        }
    })
}