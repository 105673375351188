import Api from "configs";
import { useMutation } from "@tanstack/react-query";
import UpdateMenu from 'types/menu'
export const useCreateMenu = () => {
    return useMutation({
        mutationKey: ['createMenu'],
        mutationFn: async (body: UpdateMenu) => {
            const { data } = await Api.post(`/admin/menu`, body);
            return data;
        }
    })
}

export const useUpdateMenu = () => {
    return useMutation({
        mutationKey: ['useUpdateMenu'],
        mutationFn: async (variables: { id: string, body: UpdateMenu }) => {
            const { id, body } = variables;
            const { data } = await Api.put(`/menu/${id}`, body);
            return data;
        }
    })
}

export const useToggleMenu = () => {
    return useMutation({
        mutationKey: ['toggleMenu'],
        mutationFn: async (id: string) => {
            const { data } = await Api.put(`/menu/toggleStatus/${id}`);
            return data;
        }
    })
}

export const useDeleteMenu = () => {
    return useMutation({
        mutationKey: ['deleteMenu'],
        mutationFn: async (id: string) => {
            const { data } = await Api.delete(`/admin/menu/${id}`);
            return data;
        }
    })
}