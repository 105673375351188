import React from 'react';
import ReactDOM from 'react-dom/client';
import {ConfigProvider} from 'antd';
import {StyleProvider} from '@ant-design/cssinjs';

import {BrowserRouter} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import './index.css';
import App from './App.tsx';
import reportWebVitals from './reportWebVitals';
import viVN from 'antd/locale/vi_VN';


import 'dayjs/locale/vi';
import dayjs from 'dayjs';

dayjs.locale('vi');

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: 1,
      staleTime: 5 * 1000,
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StyleProvider>
        <ConfigProvider locale={viVN} theme={{ cssVar: true }}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ConfigProvider>
      </StyleProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
