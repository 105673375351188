import { AxiosError } from 'axios';
import Api from "configs";
import { useQuery } from "@tanstack/react-query";
import GetResponse from 'types/GetResponse';
import GetFilters from 'types/GetFilter';

export const useGetReport = (filters: GetFilters) => {
    const {
        pageSize = 10,
        page = 1,
        search
    } = filters;
    const options = {
        query: {
          
        },
        pageSize: pageSize,
        page: page
    }
    if(search){
        options.query = {
         ...options.query,
            search: search
        }
    }
    return useQuery<GetResponse, AxiosError>({
        queryKey: ['useGetReport', options], 
        queryFn: async () => {
            const { data } = await Api.get('/reports', { params: options });
            return data;
        },
        staleTime: 1000 * 60 * 5
    });
}