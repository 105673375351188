import React, {useState, useTransition} from 'react';
import {Button, Form, Input, Popconfirm, Avatar, Tag} from 'antd';
import type {TableColumnsType} from 'antd';
import type {SearchProps} from 'antd/es/input/Search';

import {
  PlusOutlined,
  UnlockOutlined,
  LockOutlined,
  EditFilled,
  DollarOutlined,
} from '@ant-design/icons';

import {useGetShipers} from 'hooks/shiper/useGetShipers';
import {useDeleteShipers} from 'hooks/shiper/useUpdateShiper';
import {useToggleUser, useVerifyUser} from 'hooks/users/useUpdateUsers';
import {PaginationCustom} from 'components/element/PaginationCustom';
import {TableCustom} from 'components/element/TableCustom';
import {LoadedMoney} from 'components/element/LoadedMoney';
import {
  addKeyData,
  formatNumberNegativeToString,
  formatNumberValue,
  formatDate,
} from 'utils';
import {FormShip} from 'components/ship/FormShip';

export const Shiper: React.FC = () => {
  const [isPending, startTransition] = useTransition();

  const [form] = Form.useForm();
  const [formToMoney] = Form.useForm();
  const [filters, setFilters] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isId, setIsId] = useState('');
  const {data: getData, isLoading, refetch} = useGetShipers(filters);
  const {mutateAsync: onDeleteShipers,isLoading: isLoadingDelete} = useDeleteShipers()
  const {mutateAsync: onToggleUser} = useToggleUser();
  const {mutateAsync: onVerifyUser} = useVerifyUser();
  const handleconfirm = async (id: string) => {
    await onToggleUser(id);
    await refetch();
  };
  const columns: TableColumnsType = [
    {title: 'Tên Shiper', dataIndex: 'name', key: 'name', align: 'center'},
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      align: 'center',
      render: data => {
        return <Avatar src={data} />;
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'user',
      key: 'numberPhone',
      align: 'center',
      render: data => {
        return <p>{data.numberPhone}</p>;
      },
    },
    {title: 'Địa chỉ', dataIndex: 'address', key: 'address', align: 'center'},
    {
      title: 'Tiền trong ví',
      dataIndex: 'balance',
      key: 'balance',
      align: 'center',
      sorter: true,
      render: data => {
        return (
          <div>
            <p>{formatNumberValue(data)}</p>
          </div>
        );
      },
    },
    {
      title: 'Biển số xe',
      dataIndex: 'vehicleBKS',
      key: 'vehicleBKS',
      align: 'center',
    },
    {
      title: 'Tên xe',
      dataIndex: 'vehicleName',
      key: 'vehicleName',
      align: 'center',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'user',
      key: 'isBlocked',
      align: 'center',
      width: 130,
      render: (user: any) => {
        return (
          <Popconfirm
            title={`${user.isBlocked ? 'Unblock' : 'Block'}`}
            description={`Bạn có chắc muốn ${
              user.isBlocked ? 'Unblock' : 'Block'
            }`}
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleconfirm(user?._id)}>
            {!user.isBlocked ? (
              <Button icon={<UnlockOutlined />} type="primary">
                Unblock
              </Button>
            ) : (
              <Button
                icon={<LockOutlined />}
                className="!border-white bg-[#ed250e] hover:!bg-[#f25c4b] !text-white !font-medium">
                Block
              </Button>
            )}
          </Popconfirm>
        );
      },
    },
    {
      title: 'Xác minh',
      dataIndex: 'user',
      key: 'verified',
      align: 'center',
      width: 130,
      render: (data: any) => {
        return (
          <Popconfirm
            title={`Kích hoạt người dùng`}
            description={`Bạn có chắc muốn kích hoạt người dùng`}
            okText="Yes"
            disabled={data.verified}
            cancelText="No"
            onConfirm={async () => {
              await onVerifyUser(data?._id);
              await refetch();
            }}>
            <div>
              {data.verified ? (
                <Tag color="success">Đã xác minh</Tag>
              ) : (
                <Tag className="verify" color="error">
                  Chưa xác minh
                </Tag>
              )}
            </div>
          </Popconfirm>
        );
      },
    },
    {
      title: 'Tình trạng',
      dataIndex: 'isActive',
      align: 'center',
      key: 'isActive',
      width: 130,
      render: (data: any) => {
        return (
          <div>
            {data ? (
              <Tag color="success">Đang hoạt động</Tag>
            ) : (
              <Tag color="error">Ngừng hoạt động</Tag>
            )}
          </div>
        );
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatDate(data)}</p>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      align: 'center',
      width: 300,
      render: (text, record: any) => {
        return (
          <div className="flex justify-center gap-2 px-0">
            <Button
              className="!border-white	 bg-[#2bec82] hover:!bg-[#2bec82] !text-white !font-medium"
              onClick={() => {
                formToMoney.resetFields();
                const data = {
                  ...record,
                  balance: formatNumberNegativeToString(record.balance || 0),
                };
                formToMoney.setFieldsValue(data);
                setIsOpen(true);
              }}
              icon={<DollarOutlined />}>
              Nạp tiền
            </Button>

            <Button
              onClick={() => {
                const data = {
                  ...record,
                  numberPhone: record.user.numberPhone,
                };
                setIsId(data?._id);
                form.setFieldsValue({
                  ...data,
                  'infoBankAccount.name': data.infoBankAccount.name,
                  'infoBankAccount.number': data.infoBankAccount.number,
                  'infoBankAccount.qr': data.infoBankAccount.qr,
                });
                setIsShowPopup(true);
              }}
              icon={<EditFilled />}
              className="!border-white	 bg-[#eca52b] hover:!bg-[#edb555] !text-white !font-medium">
              Chỉnh sửa
            </Button>
            <Popconfirm
              title="Xoá tài xế"
              description="Bạn có chắc muốn xoá tài xế này không ?"
              onConfirm={ async()=>{
                    await onDeleteShipers(record?._id);
                    await refetch();
    
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No">
              <Button loading={isLoadingDelete} className="!border-white bg-[#ed250e] hover:!bg-[#f25c4b] !text-white !font-medium">
                Xoá
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onSeachValue: SearchProps['onSearch'] = (value, _e, info) => {
    setFilters({...filters, search: value});
  };
  const handleTableChange = (filter: any, sorter: any) => {
    if (sorter) {
      setFilters({...filters, sorter: sorter});
    } else {
      setFilters({...filters, sorter: null});
    }
  };
  return (
    <div className="container mx-auto">
      <div className="flex-col gap-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Tài xế</h2>
          <div className="flex gap-2">
            <div>
              <Input.Search
                className="w-96"
                placeholder="Search theo tên hoặc số điện thoại"
                onSearch={onSeachValue}
                enterButton
              />
            </div>
            <Button
              className="font-bold"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                startTransition(() => {
                  setIsId('');

                  setIsShowPopup(true);
                });
              }}>
              Thêm tài xế
            </Button>
          </div>
        </div>
        <div className=" flex flex-col items-end gap-3">
          <TableCustom
            isLoading={isLoading}
            columns={columns}
            data={addKeyData(getData?.result)}
            onChange={handleTableChange}
          />
          <PaginationCustom
            totalPage={getData?.totalPage ?? 1}
            current={getData?.page ?? 1}
            pageSize={getData?.pageSize ?? 10}
            onChange={(page, pageSize) => {
              setFilters({...filters, page, pageSize});
            }}
            onShowSizeChange={(current, size) => {
              setFilters({...filters, page: current, pageSize: size});
            }}
          />
          {isOpen ? (
            <LoadedMoney
              form={formToMoney}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              refetch={refetch}
            />
          ) : null}
        </div>
      </div>
      <FormShip
        form={form}
        isShowPopup={isShowPopup}
        isId={isId}
        setIsShowPopup={setIsShowPopup}
        setIsId={setIsId}
        refetch={refetch}
      />
    </div>
  );
};
