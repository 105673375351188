import React, {useState, useTransition} from 'react';
import {Button, Form, Input, Popconfirm, Tag, message} from 'antd';
import type {TableColumnsType} from 'antd';
import type {SearchProps} from 'antd/es/input/Search';

import {
  PlusOutlined,
  EditFilled,
  DollarOutlined,
  ReadOutlined,
  UnlockOutlined,
  LockOutlined,
  CopyOutlined,
} from '@ant-design/icons';

import {useGetShops} from 'hooks/shop/useGetShop';
import {useUpdateShopActive} from 'hooks/shop/useUpdateShop';
import {useNavigate} from 'react-router-dom';

import {TableCustom} from 'components/element/TableCustom';
import {PaginationCustom} from 'components/element/PaginationCustom';
import {
  addKeyData,
  formatNumberToString,
  formatNumberValue,
  formatDate,
} from 'utils';
import {useToggleUser, useVerifyUser} from 'hooks/users/useUpdateUsers';
import {LoadedMoney} from 'components/element/LoadedMoney';
export const Shop: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [filters, setFilters] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isPending, startTransition] = useTransition();
  const {data: getData, isLoading, refetch} = useGetShops(filters);
  const {mutateAsync: onToggleActive} = useUpdateShopActive();
  const {mutateAsync: onToggleUser} = useToggleUser();
  const {mutateAsync: onVerifyUser} = useVerifyUser();
  const handleConfirm = async (id: string) => {
    await onToggleUser(id);
    await refetch();
  };
  const handleIsActive = async (id: string) => {
    await onToggleActive(id);
    await refetch();
  };
  const handleCopyLink = async (id: string) => {
    const link = `https://merchant.thienloi.com/merchant/${id}`;
    try {
      await navigator.clipboard.writeText(link);
      message.success('Sao chép thành công');
    } catch (error) {
      message.error('Lỗi sao chép');
    }
  };
  const columns: TableColumnsType = [
    {title: 'Tên Shop', dataIndex: 'name', key: 'shop', fixed: 'left'},
    {
      title: 'Số điện thoại',
      dataIndex: 'user',
      key: 'numberPhone',
      render: (data: any) => {
        return <div>{data?.numberPhone}</div>;
      },
    },
    {
      title: 'Tiền trong ví',
      dataIndex: 'balance',
      key: 'balance',
      align: 'center',
      sorter: true,
      render: data => {
        return (
          <div>
            <p>{formatNumberValue(data)}</p>
          </div>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'user',
      key: 'block',
      align: 'center',
      width: 130,
      render: (data: any) => {
        return (
          <Popconfirm
            title={`${data?.isBlocked ? 'Unblock' : 'Block'}`}
            description={`Bạn có chắc muốn ${
              data?.isBlocked ? 'Unblock' : 'Block'
            }`}
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleConfirm(data?._id)}>
            {!data?.isBlocked ? (
              <Button type="primary" icon={<UnlockOutlined />}>
                Unblock
              </Button>
            ) : (
              <Button
                icon={<LockOutlined />}
                className="!border-white bg-[#ed250e] hover:!bg-[#f25c4b] !text-white !font-medium">
                Block
              </Button>
            )}
          </Popconfirm>
        );
      },
    },

    {
      title: 'Xác minh',
      dataIndex: 'user',
      key: 'verified',
      align: 'center',
      width: 130,
      render: (data: any) => {
        return (
          <Popconfirm
            title={`Kích hoạt người dùng`}
            description={`Bạn có chắc muốn kích hoạt người dùng`}
            okText="Yes"
            disabled={data?.verified}
            cancelText="No"
            onConfirm={async () => {
              await onVerifyUser(data?._id);
              await refetch();
            }}>
            <div>
              {data?.verified ? (
                <Tag color="success">Đã xác minh</Tag>
              ) : (
                <Tag className="verify" color="error">
                  Chưa xác minh
                </Tag>
              )}
            </div>
          </Popconfirm>
        );
      },
    },
    {
      title: 'Cấp phép',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 180,
      render: (isActive: any, data: any) => {
        return (
          <Popconfirm
            title={`Cấp phép`}
            description={`Bạn có chắc muốn ${
              !isActive ? 'cấp phép' : 'thu hồi cấp phép'
            }`}
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              if (data?.systemConfigId) {
                handleIsActive(data?._id);
              } else {
                message.error(
                  'Bảng giá chưa được chọn đề nghị cập nhật bảng giá trước khi cấp giấy phép!!!',
                );
              }
            }}>
            {isActive ? (
              <Button type="primary" icon={<UnlockOutlined />}>
                Đã cấp phép
              </Button>
            ) : (
              <Button
                icon={<LockOutlined />}
                className="!border-white bg-[#ed250e] hover:!bg-[#f25c4b] !text-white !font-medium">
                Chưa cấp phép
              </Button>
            )}
          </Popconfirm>
        );
      },
    },
    {
      title: 'Tình trạng',
      dataIndex: 'isOpen',
      align: 'center',
      key: 'isOpen',
      width: 130,
      render: (data: any) => {
        return (
          <div>
            {data ? (
              <Tag color="success">Đang hoạt động</Tag>
            ) : (
              <Tag color="error">Ngừng hoạt động</Tag>
            )}
          </div>
        );
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatDate(data)}</p>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      align: 'center',
      width: 200,
      render: (text, record: any) => {
        return (
          <div className="flex flex-col justify-center gap-2 px-0 py-[4px]">
            <Button
              className="!border-white	 bg-[#2bec82] hover:!bg-[#2bec82] !text-white !font-medium"
              onClick={() => {
                form.resetFields();
                const data = {
                  ...record,
                  balance: formatNumberToString(record.balance || 0),
                };
                form.setFieldsValue(data);
                setIsOpen(true);
              }}
              icon={<DollarOutlined />}>
              Nạp tiền
            </Button>
            <Button
              icon={<ReadOutlined />}
              type="primary"
              onClick={() => {
                startTransition(() => {
                  navigate(`/shop/menu/${record?._id}`, {
                    state: {from: `/shop`},
                  });
                });
              }}>
              Chi tiết menu
            </Button>
            <Button
              onClick={() => {
                startTransition(() => {
                  navigate(`/shop/edit/${record?._id}`, {
                    state: {from: '/shop'},
                  });
                });
              }}
              icon={<EditFilled />}
              className="!border-white	 bg-[#eca52b] hover:!bg-[#edb555] !text-white !font-medium">
              Chỉnh sửa
            </Button>
            <Button
              onClick={() => {
                startTransition(() => {
                  handleCopyLink(record?._id);
                });
              }}
              icon={<CopyOutlined />}
              className="!border-white bg-[#ed250e] hover:!bg-[#f25c4b] !text-white !font-medium">
              Share Cửa Hàng
            </Button>
          </div>
        );
      },
    },
  ];
  const onSeachValue: SearchProps['onSearch'] = (value, _e, info) => {
    setFilters({...filters, search: value});
  };
  const handleTableChange = (filter: any, sorter: any) => {
    if (sorter) {
      setFilters({...filters, sorter: sorter});
    } else {
      setFilters({...filters, sorter: null});
    }
  };
  return (
    <div className="container mx-auto">
      <div className="flex-col gap-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Cửa hàng</h2>
          <div className="flex gap-2">
            <div>
              <Input.Search
                className="w-96"
                placeholder="Search theo tên hoặc số điện thoại"
                onSearch={onSeachValue}
                enterButton
              />
            </div>
            <Button
              className="font-bold"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                startTransition(() => {
                  navigate('/shop/create', {
                    state: {from: '/shop'},
                  });
                });
              }}>
              Add Shop
            </Button>
          </div>
        </div>
        <div className=" flex flex-col items-end gap-3">
          <TableCustom
            isLoading={isLoading}
            columns={columns}
            data={addKeyData(getData?.result)}
            onChange={handleTableChange}
          />
          <PaginationCustom
            totalPage={getData?.totalPage ?? 1}
            current={getData?.page ?? 1}
            pageSize={getData?.pageSize ?? 10}
            onChange={(page, pageSize) => {
              setFilters({...filters, page, pageSize});
            }}
            onShowSizeChange={(current, size) => {
              setFilters({...filters, page: current, pageSize: size});
            }}
          />
          {isOpen ? (
            <LoadedMoney
              form={form}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              refetch={refetch}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
