import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {message, Button} from 'antd';
import type {TableColumnsType} from 'antd';
import {useGetBanlance} from 'hooks/balance';
import {RollbackOutlined} from '@ant-design/icons';
import {PaginationCustom} from 'components/element/PaginationCustom';
import {TableCustom} from 'components/element/TableCustom';
import {addKeyData, formatDate,formatNumberValue} from 'utils';
export const BalanceHistory: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [filters, setFilters] = useState({});
  const {
    data: getData,
    isLoading,
    isSuccess,
    isError,
  } = useGetBanlance({...filters, userID: params?.id});
 
  if (isError) {
    message.error('Không tìm thấy thông tin giao dịch');
  }
  const columns: TableColumnsType = [
    {
      title: 'Nội dung',
      dataIndex: 'reason',
      key: 'code',
      align: 'center',
    },
    {
      title: 'Số dư cũ',
      dataIndex: 'balance',
      key: 'balance',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatNumberValue(data)}</p>
          </div>
        );
      },
    },
    {
      title: 'Số dư biến động',
      dataIndex: 'loadedBalance',
      key: 'loadedBalance',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatNumberValue(data)}</p>
          </div>
        );
      },
    },
    {
      title: 'Số dư cuối cùng',
      dataIndex: 'newBalance',
      key: 'newBalance',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatNumberValue(data)}</p>
          </div>
        );
      },
    },
    {
      title: 'Ngày thay đổi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatDate(data)}</p>
          </div>
        );
      },
    },
  ];
  return (
    <div className="container mx-auto">
      <div className="flex-col gap-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Lịch sử giao dịch</h2>
          <div className="flex gap-2">
            <Button
              icon={<RollbackOutlined />}
              onClick={() => {
                navigate(-1);
              }}>
              Trở về
            </Button>
          </div>
        </div>
        <div className=" flex flex-col items-end gap-3">
          <TableCustom
            isLoading={isLoading}
            columns={columns}
            data={addKeyData(getData?.result)}
          />
          <PaginationCustom
            totalPage={getData?.totalPage ?? 1}
            current={getData?.page ?? 1}
            pageSize={getData?.pageSize ?? 10}
            onChange={(page, pageSize) => {
              console.log(page, pageSize);
              setFilters({...filters, page, pageSize});
            }}
            onShowSizeChange={(current, size) => {
              setFilters({...filters, page: current, pageSize: size});
            }}
          />
        </div>
      </div>
    </div>
  );
};
