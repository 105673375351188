import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Row, Col, Form, Input, Modal, message, Button} from 'antd';
import type {FormInstance} from 'antd';
import {useLoadedMoney} from 'hooks/users/useUpdateUsers';
import {formatNumberNegativeToString, formatStringToNumber} from 'utils';
interface Props {
  form: FormInstance<any>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: ()=>void;
}

export const LoadedMoney: React.FC<Props> = ({form, isOpen, setIsOpen,refetch}) => {
  const {mutateAsync: onUpdata, isLoading, isError} = useLoadedMoney();
  const navigate = useNavigate();
  const onSubmit = async () => {
    try {
      const values = await form.getFieldsValue();
      const data = {
        role: form.getFieldValue('user')?.role,
        newBalance: formatStringToNumber(values.changeBalance),
        description: values.description,
        id: form.getFieldValue('_id'),
      };
      await onUpdata(data);
      refetch();
      setIsOpen(false);
    } catch (error) {
      if (isError) {
        message.error('Nạp tiền không thành công đề nghị kiểm tra lại');
      }
    }
  };
  const normalizePrice = (value: any) => {
    return formatNumberNegativeToString(value);
  };
  const onValuesChange = (e: any) => {
    const filedName = Object.keys(e)[0];
    const value = e[filedName];
    if (filedName === 'changeBalance' && value) {
      const balance = form.getFieldValue('balance');
      const sum = formatStringToNumber(balance) + formatStringToNumber(value);
      form.setFieldsValue({newBalance: formatNumberNegativeToString(sum)});
    }
  };
  return (
    <Modal
      title={`Nạp tiền cho ${form.getFieldValue('name')}`}
      open={isOpen}
      okText="Lưu"
      cancelText="Huỷ"
      onOk={onSubmit}
      confirmLoading={isLoading}
      onCancel={() => {
       // form.resetFields();
        setIsOpen(false);
      }}>
      {' '}
      <div className="container mx-auto ">
        <Form form={form} onValuesChange={onValuesChange} className="py-1">
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Tiền hiện có</label>
                <Form.Item name="balance" className="mb-0">
                  <Input
                    readOnly
                    placeholder="Số tiền hiện có"
                    addonAfter="VNĐ"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">
                  Tiền nạp thêm (* Có thể trừ)
                </label>
                <Form.Item
                  name="changeBalance"
                  className="mb-0"
                  normalize={normalizePrice}
                  rules={[
                    {
                      required: true,
                      message: 'Không được bỏ trống !',
                    },
                  ]}>
                  <Input placeholder="Nạp thêm"  addonAfter="VNĐ" />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Số tiền sau khi nạp</label>
                <Form.Item name="newBalance" className="mb-0">
                  <Input readOnly placeholder="Số sau khi nạp"  addonAfter="VNĐ" />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="flex items-end w-full h-full">
              
                <Button onClick={()=>{
                  const id = form.getFieldValue('user')?._id;                 
                  navigate(`/balance/${id}`);
                }} type='primary'>Biến động số dư </Button>
              </div>
            </Col>
            <Col span={24}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Chú thích</label>
                <Form.Item name="description" className="mb-0">
                  <Input.TextArea
                    autoSize={{minRows: 4, maxRows: 6}}
                    placeholder="Chú thích"
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
