import Api from 'configs';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import UpdateShop from 'types/shop';

export const useCreateShop = () => {
  return useMutation({
    mutationKey: ['createShop'],
    mutationFn: async (body: UpdateShop) => {
      const {data} = await Api.post(`/admin/shops`, body);
      return data;
    },
  });
};

export const useUpdateShop = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['updateShop'],
    mutationFn: async (variables: {
      body: UpdateShop;
      idShop: string;
      idQuery: string;
    }) => {
      const {idShop, body, idQuery} = variables;
      const {data} = await Api.put(`/admin/shops/${idShop}`, body);
      queryClient.invalidateQueries(['useGetShopById', idQuery]);
      return data;
    },
  });
};

export const useUpdateShopActive = () => {
  return useMutation({
    mutationKey: ['useUpdateShopActive'],
    mutationFn: async (idShop: string) => {
      const {data} = await Api.put(`/admin/shops/active/${idShop}`);
      return data;
    },
  });
};
