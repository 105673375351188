import Api from "configs";
import { useMutation } from "@tanstack/react-query";
import SystemConfig from "types/systemConfig";

export const useUpdateSystemConfig = ()=>{
    return useMutation({
        mutationKey: ['updateSystemConfig'],
        mutationFn: async (variables: { body: SystemConfig, id: string }) => {
            const { id, body } = variables;
            const { data } = await Api.put(`/system-config/${id}`, body);
            return data;
        }
    })
}


export const useCreateSystemConfig = ()=>{
    return useMutation({
        mutationKey: ['createSystemConfig'],
        mutationFn: async (body : SystemConfig) => {
            const { data } = await Api.post(`/system-config`, body);
            return data;
        }
    })
}