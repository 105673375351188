import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Input, Button, message} from 'antd';
import {useMutation} from '@tanstack/react-query';
import {useAuthStore} from 'stores/auth';
import {loginAdmin} from 'configs/auth.api';
export const AuthenticationPage: React.FC = () => {
  const {setLogin, isAuthenticated} = useAuthStore(state => state);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    document.title = "Đăng nhập"
    if (isAuthenticated) {
      navigate('/', {replace: true, state: {from: '/login'}});
    }
  },[]);
  const {
    mutate: loginFC,
    isLoading,
  } = useMutation({
    mutationFn: async () =>
      await loginAdmin({numberPhone: username, password: password}),
    onSuccess: data => onSuccess(data),
    onError: error => messageApi.error('Tài khoản hoặc mật khẩu không đúng'),
  });
  //
  const handleLogin = () => {
    if (isLoading) return;
    loginFC();
  };
  const onSuccess = (data: any) => {
    const {token, role} = data;
    if (role === 'ADMIN') {
      localStorage.setItem('token', token);
      setLogin();
      navigate('/');
    } else {
      messageApi.error('Tài khoản hoặc mật khẩu không đúng');
    }
  };
  return (
    <div className="container-login">
      <div className="wrapper">
        {contextHolder}
        <h1>Đăng nhập</h1>
        <div className="input-box">
          <Input
            value={username}
            onChange={e => {
              setUsername(e.target.value);
            }}
            type="text"
            placeholder="Số điện thoại"
            required
          />
        </div>
        <div className="input-box">
          <Input
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            type="password"
            placeholder="Mật khẩu"
            required
          />
        </div>
        {/* <div className="remember-forgot">
          <label>
            <input type="checkbox" />
            Remember me
          </label>
          <a href="#"> Forgot Password?</a>
        </div> */}
        <Button loading={isLoading} className="btn" onClick={handleLogin}>
          Đăng nhập
        </Button>
        {/* <div className="register-link">
          <p>
            Don't have an account? <a href="#">Register</a>
          </p>
        </div> */}
      </div>
    </div>
  );
};
