import React, {useEffect, useState} from 'react';
import {Form, Row, Col, Input, Select, Upload, Button} from 'antd';
import type {FormInstance} from 'antd';

import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {AddItemForm} from './component/AddItemForm';
import {formatNumberToString} from 'utils';

import {useUpload} from 'hooks/upload/useUpload';

interface Props {
  form: FormInstance<any>;
  dataShop: any;
}

export const FormMenu: React.FC<Props> = ({form, dataShop}) => {
  const {mutateAsync: upLoadImage, isLoading , isSuccess} = useUpload();
  const [imageUrl, setImageUrl] = useState<string | undefined>(
    form.getFieldValue('image') || undefined,
  );

  const normalizePrice = (value: any) => {
    return formatNumberToString(value);
  };
  useEffect(() => {
    setImageUrl(form.getFieldValue('image') || undefined);
  }, [form.getFieldValue('image')]);

  const beforeUpload = async (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      console.log('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      console.log('Image must smaller than 2MB!');
    }
    return false;
  };
  const handleChange = async (e: any) => {
    const {file} = e;
    const formData = new FormData();
    formData.append('file', file);
    const res = await upLoadImage({body: formData});
    setImageUrl(res);
    form.setFieldsValue({
      image: res,
    });
  };
  const uploadButton = (
    <Button style={{border: 0,background: 'transparent', boxShadow: 'none'}}>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{marginTop: 8}}>Upload</div>
    </Button>
  );

  const optionsType = (arr = []) => {
    const newArrary = arr.map((item: any) => {
      return {
        label: item,
        value: item,
      };
    });
    return newArrary;
  };
  // const normFile = async (e: any) => {
  //   console.log('Upload event:', imageUrl);
  //   return  imageUrl;
  // };

  return (
    <div className="container mx-auto">
      <Form form={form}>
        <Row gutter={[24, 24]}>
          <Col xs={24} md={6}>
            <div className="flex flex-col gap-2">
              <label className="text-2l font-bold">Hình ảnh</label>
              <Form.Item
                name="image"
                valuePropName="uploadImage"
                className="mb-0 flex justify-center"
                // getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture-card"
                  showUploadList={false}
                  onChange={handleChange}
                  beforeUpload={beforeUpload}>
                  {form.getFieldValue('image')  ? (
                    <img src={form.getFieldValue('image')} alt="avatar" style={{width: '100%'}} />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} md={18}>
            <div className="flex flex-col gap-2">
              <label className="text-2l font-bold">Tên món ăn</label>
              <Form.Item
                name="name"
                className="mb-0"
                rules={[
                  {
                    required: true,
                    message: 'Không được bỏ trống !',
                  },
                ]}>
                <Input placeholder="Tên món ăn" />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="flex flex-col gap-2">
              <label className="text-2l font-bold">Giá</label>
              <Form.Item
                name="price"
                className="mb-0"
                normalize={normalizePrice}
                rules={[
                  {
                    required: true,
                    message: 'Không được bỏ trống !',
                  },
                ]}>
                <Input placeholder="Giá món ăn" />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="flex flex-col gap-2">
              <label className="text-2l font-bold">Loại</label>
              <Form.Item
                name="type"
                className="mb-0"
                rules={[
                  {
                    required: true,
                    message: 'Không được bỏ trống !',
                  },
                ]}>
                <Select options={optionsType(dataShop?.type)} />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="flex flex-col gap-2">
              <label className="text-2l font-bold">Size</label>
              <AddItemForm name="sizes" label="Kích thước" />
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="flex flex-col gap-2">
              <label className="text-2l font-bold">Thành phần</label>
              <AddItemForm name="ingredients" label="Thành phần" />
            </div>
          </Col>
          <Col span={24}>
            <div className="flex flex-col gap-2">
              <label className="text-2l font-bold">Mô tả</label>
              <Form.Item name="description" className="mb-0">
                <Input.TextArea
                  autoSize={{minRows: 4, maxRows: 8}}
                  placeholder="Mô tả món ăn"
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
