import Api from "configs";
import { useMutation } from "@tanstack/react-query";
export const uploadImage = (form: FormData) =>
  Api.post('/upload', form, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    },
  });

export const useUpload = ()=>{
    return useMutation({
        mutationKey: ['upload'],
        mutationFn: async (variables: { body: FormData }) => {
            const { body } = variables;
            const { data } = await uploadImage(body);
            return data;
        }
    })
}