import React, {useTransition} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Form, message} from 'antd';
import {RollbackOutlined, SaveOutlined} from '@ant-design/icons';
import {useCreateShop} from 'hooks/shop/useUpdateShop';
import {FormShop} from 'components/shop/FormShop';
export const CreateShop: React.FC = () => {
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();
  const [form] = Form.useForm();
  const {mutateAsync: onCreateShop, isLoading, isError} = useCreateShop();
  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      await onCreateShop(values);
      navigate('/shop', {state: {from: '/shop/create'}});
    } catch (error) {
      if (error?.data?.message) {
        message.error(error?.data?.message);
      }
    }
  };
  return (
    <div className="container mx-auto">
      <div className="flex-col gap-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Thêm Shop</h2>
          <div className="flex gap-3 items-center">
            <Button
              icon={<RollbackOutlined />}
              onClick={() =>
                navigate('/shop', {state: {from: '/shop/create'}})
              }>
              Trở về
            </Button>
            <Button
              loading={isLoading}
              type="primary"
              icon={<SaveOutlined />}
              onClick={() => {
                startTransition(() => {
                  handleSave();
                });
              }}>
              Lưu
            </Button>
          </div>
        </div>
        <FormShop form={form} isEdit={false} />
      </div>
    </div>
  );
};
