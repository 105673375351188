import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Menu} from 'antd';
import type {MenuProps} from 'antd';

import {leftMenu} from 'routers/menu';
export const LeftMenu: React.FC = () => {
  const param = useLocation();
  const navigate = useNavigate();
  const [keyMenu, setKeyMenu] = useState('/shop');
  const onClickMenu: MenuProps['onClick'] = e => {
    const path = keyMenu;
    navigate(e.key, {state: {from: path,key: e.key}});
    setKeyMenu(e.key);
  };
 
  useEffect(()=>{
    if(param?.pathname){
      if(param?.pathname.includes('/shop')){
        setKeyMenu('/shop');
        document.title = "Cửa hàng";
      }
      else if(param?.pathname.includes('/user')){
        setKeyMenu('/user');
        document.title = "Người dùng";
      }
      else {
        setKeyMenu(param?.pathname);
        document.title = leftMenu.find((item)=> item.path.includes(param?.pathname))?.label || "";
      };
    }
  },[param?.pathname])
  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[keyMenu]}
      onClick={onClickMenu}
      items={leftMenu}
    />
  );
};
