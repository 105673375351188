import React, { useState} from 'react';
import {useGetStatisticsUser} from 'hooks/statistics';
import {Select} from 'antd';
import {Pie} from 'react-chartjs-2';

export const UserStatistic: React.FC = () => {
  const [role, setRole] = useState('USER');
  const {data} = useGetStatisticsUser({role});


  const formatData = (item: any) => {
    const labels = ['Không bị khoá', 'Đang bị khoá'];
    const newData = {
      labels: labels,
      datasets: [
        {
          label: 'Số lượng',
          data: [
            item?.totalUserUnBlockCount || 0,
            item?.totalUserBlockCount || 0,
          ],
          backgroundColor: ['green', 'red'],
          borderWidth: 1,
        },
      ],
    };
    return newData;
  };
  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <div className="flex justify-between">
          {' '}
          <div>
            <h1>Thống kê người dùng</h1>
          </div>
          <div className="flex items-center">
            <Select
              value={role}
              onChange={e => {
                setRole(e);
              }}
              style={{width: 150}}>
              <Select.Option value="USER">Khách hàng</Select.Option>
              <Select.Option value="SHOP">Cửa hàng</Select.Option>
              <Select.Option value="SHIP">Tài xế</Select.Option>
            </Select>
          </div>
        </div>
        <div
          className="flex justify-center "
          style={{
            width: '100%',
            height: 500,
          }}>
          <Pie data={formatData(data)} />
        </div>
      </div>
    </div>
  );
};
