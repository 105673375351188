import React, {useState, useTransition} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Tag, Popconfirm, message, Spin} from 'antd';
import type {TableColumnsType} from 'antd';
import {
  EditFilled,
  ReadOutlined,
  UnlockOutlined,
  LockOutlined,
} from '@ant-design/icons';
import {
  useGetShopToSystem,
  useGetSystemConfigById,
} from 'hooks/useSystemConfig/useGetSystemConfig';
import {TableCustom} from 'components/element/TableCustom';
import {PaginationCustom} from 'components/element/PaginationCustom';
import {RollbackOutlined} from '@ant-design/icons';
import {useToggleUser} from 'hooks/users/useUpdateUsers';
import {addKeyData,formatDate} from 'utils/index';

export const ShopToSystem: React.FC = () => {
  const [isPending, startTransition] = useTransition();
  const [filters, setFilters] = useState({});
  const navigate = useNavigate();
  const params = useParams();
  const {data: dataById,isLoading:isLoadingById,} = useGetSystemConfigById(
    params?.id || '',
  );
  const {data, isLoading, refetch, isError} = useGetShopToSystem({
    ...filters,
    systemConfigId: params?.id || '',
  });
  if (isError) {
    message.error('Không tìm thấy cửa hàng');
  }
  const {mutateAsync: onToggleUser} = useToggleUser();

  const handleConfirm = async (id: string) => {
    await onToggleUser(id);
    await refetch();
  };
  const columns: TableColumnsType = [
    {title: 'Tên Shop', dataIndex: 'name', key: 'shop'},
    {
      title: 'Số điện thoại',
      dataIndex: 'user',
      key: 'numberPhone',
      render: (data: any) => {
        return <div>{data.numberPhone}</div>;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'user',
      key: 'block',
      render: (data: any) => {
        return (
          <Popconfirm
            title={`${data.isBlocked ? 'Unblock' : 'Block'}`}
            description={`Bạn có chắc muốn ${
              data.isBlocked ? 'Unblock' : 'Block'
            }`}
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleConfirm(data?._id)}>
            {data.isBlocked ? (
              <Button type="primary" icon={<UnlockOutlined />}>
                Unblock
              </Button>
            ) : (
              <Button
                icon={<LockOutlined />}
                className="!border-white bg-[#ed250e] hover:!bg-[#f25c4b] !text-white !font-medium">
                Block
              </Button>
            )}
          </Popconfirm>
        );
      },
    },
    {
      title: 'Xác minh',
      dataIndex: 'user',
      key: 'verified',
      render: (data: any) => {
        return (
          <div>
            {data.verified ? (
              <Tag color="success">Đã xác minh</Tag>
            ) : (
              <Tag color="error">Chưa xác minh</Tag>
            )}
          </div>
        );
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatDate(data)}</p>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      align: 'center',
      width: 300,
      render: (text, record: any) => {
        return (
          <div className="flex gap-4 px-0">
            <Button
              icon={<ReadOutlined />}
              type="primary"
              onClick={() => {
                startTransition(() => {
                  navigate(`/shop/menu/${record?._id}`, {
                   
                    state: {from: `/shop`},
                  });
                });
              }}>
              Chi tiết menu
            </Button>
            <Button
              onClick={() => {
                startTransition(() => {
                  navigate(`/shop/edit/${record?._id}`, {
                   
                    state: {from: '/shop'},
                  });
                });
              }}
              icon={<EditFilled />}
              className="!border-white	 bg-[#eca52b] hover:!bg-[#edb555] !text-white !font-medium">
              Chỉnh sửa
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <Spin spinning={isLoadingById}>
      <div className="container mx-auto">
        <div className="flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold">
              Bảng giá của : {dataById?.name}
            </h2>
            <Button
              icon={<RollbackOutlined />}
              onClick={() =>
                navigate('/system-config', {
               
                  state: {from: `/system-config/shop/${params.id}}`},
                })
              }>
              Trở về
            </Button>
          </div>
          <TableCustom
            isLoading={isLoading}
            columns={columns}
            data={addKeyData(data?.result)}
          />
          <PaginationCustom
            totalPage={data?.totalPage ?? 1}
            current={data?.page ?? 1}
            pageSize={data?.pageSize ?? 10}
            onChange={(page, pageSize) => {
              setFilters({...filters, page, pageSize});
            }}
            onShowSizeChange={(current, size) => {
              setFilters({...filters, page: current, pageSize: size});
            }}
          />
        </div>
      </div>
    </Spin>
  );
};
