import React, {startTransition} from 'react';
import {Dropdown, Avatar, type MenuProps} from 'antd';
import {useNavigate} from 'react-router-dom';
import {
    UserOutlined,
    PoweroffOutlined
  } from '@ant-design/icons';
  import {useAuthStore} from 'stores/auth';

export const UserInfo: React.FC = () => {
  const navigate = useNavigate();
  const {logout} = useAuthStore(state => state);

  const items: MenuProps['items'] = [
    {
      key: '1',
      icon: <UserOutlined />,
      label: 'Admin',
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      icon: <PoweroffOutlined />,
      label: 'Đăng xuất',
      onClick: () => {
        startTransition(() => {
          logout();
          //navigate('/login');
        });
      },
    },
  ];
  return (
    <Dropdown menu={{items}} placement="bottom" trigger={['click']}>
      <div className="cursor-pointer flex items-center">
        <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }} className="w-8 h-8 mr-1" >Admin</Avatar>
        <span>Người quản lí</span>
      </div>
    </Dropdown>
  );
};
