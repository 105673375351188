
import Api from "configs";
import { useQuery } from "@tanstack/react-query";
import GetFilters from 'types/GetFilter';
import dayjs from 'dayjs';

export const useGetStatisticsOrder = (filters: GetFilters) => {
    const {
        year = dayjs().valueOf(),
        typeChart
    } = filters;
    const options = {
        query: {},
    }
    if (year) {
        options.query = {
            ...options.query,
            year
        }
    }
    if (typeChart) {
        options.query = {
            ...options.query,
            type: typeChart
        }
    }
    return useQuery({
        queryKey: ['useGetStatisticsOrder', options],
        queryFn: async () => {
            const { data } = await Api.get('/statistics/orders', { params: options });
            return data;
        },
    });
}

export const useGetStatisticsUser = (filters: GetFilters) => {
    const {
        role = ''
    } = filters;
    const options = {
        query: {},
    }
    if (role) {
        options.query = {
            ...options.query,
            role
        }
    }
    return useQuery({
        queryKey: ['useGetStatisticsUser', options],
        queryFn: async () => {
            const { data } = await Api.get('/statistics/users', { params: options });
            return data;
        },
    });
}